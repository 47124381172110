<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.withdrawalRequest.list')"
    class="px-5 py-3"
  >
    <!--    <v-col cols="12" class="text-right add&#45;&#45;button" v-if="permissions.includes('ADD')">-->
    <!--      <v-btn color="success" class="mr-0" @click="openClientForm">-->
    <!--        {{ $t('client.add') }}-->
    <!--      </v-btn>-->
    <!--    </v-col>-->
    <div class="mt-2">{{ $t('withdrawalRequest.viewTotal') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">
            {{ $t('withdrawalRequest.id') }}
          </th>
          <th class="primary--text">
            {{ $t('withdrawalRequest.agentName') }}
          </th>
          <th class="primary--text">
            {{ $t('withdrawalRequest.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('withdrawalRequest.amount') }}
          </th>
          <th class="primary--text">
            {{ $t('withdrawalRequest.status') }}
          </th>
          <th class="primary--text">
            {{ $t('withdrawalRequest.createdAt') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="`withdrawalRequest-${item.id}`">
          <td>{{ item.id }}</td>
          <td>{{ item.agent_name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.status }}</td>
          <td>{{ format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.id"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
    <modal-client></modal-client>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');
const ModalClient = () => import('@/components/modals/modal-client');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
    ModalClient,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.withdrawalRequest.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
    openClientForm() {
      this.$modal.show('modal-client', {
        type: 'new',
        formType: 'ADD',
        client: null,
        commitString: null,
        dispatchString: 'client/form/postData',
        dispatchAfterString: 'client/getInitData',
      });
    },
  },
};
</script>
